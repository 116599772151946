@tailwind base;
@tailwind components;
@tailwind utilities;
@import "rc-slider/assets/index.css";

/* Global Styling here */
@layer utilities {
  .force-text-alert,
  .force-text-alert * {
    color: #ff3d00 !important;
  }
}

body {
  background: white;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

.link {
  color: #308d8a;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.paging-dot {
  width: 16px;
  height: 16px;
}

.paging-dot > circle {
  r: 6px;
  cx: 8px;
  cy: 8px;
}

.table-tiny th,
.table-tiny td {
  padding-left: 2px;
  padding-right: 2px;
}

.rc-slider-rail,
.rc-slider-track,
.rc-slider-tracks,
.rc-slider-step {
  height: 6px;
}

.rc-slider-track,
.rc-slider-tracks {
  background-image: linear-gradient(
    45deg,
    rgb(48, 141, 138) 25%,
    rgb(79, 158, 156) 25%,
    rgb(79, 158, 156) 50%,
    rgb(48, 141, 138) 50%,
    rgb(48, 141, 138) 75%,
    rgb(79, 158, 156) 75%,
    rgb(79, 158, 156) 100%
  );
  background-size: 10px 10px;
}

.rc-slider-dot {
  border: none;
}

.rc-slider-rail {
  background-image: linear-gradient(
    45deg,
    rgb(210, 220, 227) 25%,
    rgb(202, 214, 211) 25%,
    rgb(202, 214, 211) 50%,
    rgb(210, 220, 227) 50%,
    rgb(210, 220, 227) 75%,
    rgb(202, 214, 211) 75%,
    rgb(202, 214, 211) 100%
  );
  background-size: 10px 10px;
}

.rc-slider-step .rc-slider-dot:first-child,
.rc-slider-step .rc-slider-dot:last-child {
  display: none;
}

.rc-slider-handle,
.rc-slider-handle:hover,
.rc-slider-handle:focus {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(48, 141, 138, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 32, 51, 0.1);
}

.rc-slider-dot {
  bottom: -1px;
  border-radius: 0;
  width: 4px;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border: 1px solid rgba(48, 141, 138, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 32, 51, 0.1);
}

.rc-slider-handle {
  box-shadow: 0px 4px 4px 0px rgba(0, 32, 51, 0.1);
  opacity: 1;
}

.rc-slider-mark {
  top: 16px;
}

.rc-slider-mark-text {
  font-size: 11px;
  color: rgba(133, 150, 161, 1);
  font-weight: 600;
}

.rc-slider-mark-text:first-child {
  left: 13px !important;
}
.rc-slider-mark .rc-slider-mark-text:last-child {
  left: 98% !important;
}
