@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Titles */
  .title-h1 {
    @apply text-24 leading-30 font-semibold tracking-tighter;
    /* -1.5% letter spacing converted to em */
  }

  .title-h2 {
    @apply text-20 leading-26 font-semibold tracking-tighter;
    /* -1.5% letter spacing */
  }

  .title-h3 {
    @apply text-18 leading-20 font-semibold tracking-tight;
    /* -1% letter spacing */
  }

  .title-h4 {
    @apply text-16 leading-20 font-semibold tracking-tight;
    /* -0.5% letter spacing */
  }

  /* Body text */
  .body-lg-regular {
    @apply text-18 leading-24 font-normal tracking-tight;
    /* -0.5% letter spacing */
  }

  .body-lg-semibold {
    @apply text-18 leading-24 font-semibold tracking-tight;
    /* -0.5% letter spacing */
  }

  .body-md-regular {
    @apply text-16 leading-20 font-normal tracking-tight;
    /* -0.5% letter spacing */
  }

  .body-md-semibold {
    @apply text-16 leading-20 font-semibold tracking-tight;
    /* -0.5% letter spacing */
  }

  .body-m-regular {
    @apply text-14 leading-18 font-normal tracking-tight;
  }

  .body-sm-regular {
    @apply text-14 leading-18 font-normal tracking-tight;
    /* -0.5% letter spacing */
  }

  .body-sm-semibold {
    @apply text-14 leading-18 font-semibold tracking-tight;
    /* -0.5% letter spacing */
  }

  .body-xs-semibold {
    @apply text-14 leading-18 font-semibold tracking-tight;
    /* -0.5% letter spacing */
  }

  /* Numbers */
  .numbers-lg {
    @apply text-56 leading-56 font-semibold;
    /* 0 letter spacing */
  }

  .numbers-md {
    @apply text-48 leading-48 font-semibold;
    /* 0 letter spacing */
  }

  .numbers-sm {
    @apply text-40 leading-48 font-semibold;
    /* 0 letter spacing */
  }

  /* Buttons */
  .button-lg {
    @apply text-18 leading-24 font-semibold tracking-tight;
    /* -0.5% letter spacing */
  }

  .button-md {
    @apply text-16 leading-24 font-semibold tracking-tight;
    /* -0.5% letter spacing */
  }

  /* Other */
  .ticker {
    @apply text-20 leading-24 font-semibold;
    /* 0 letter spacing */
  }

  .uppercase-label {
    @apply text-11 leading-12 font-semibold tracking-wider uppercase;
    /* 0.5px letter spacing converted to em */
  }

  @media (min-width: 768px) {
    .title-h1 {
      @apply text-40 leading-44 font-semibold tracking-tighter;
    }

    .title-h2 {
      @apply text-28 leading-34 font-semibold tracking-tighter;
    }

    .title-h3 {
      @apply text-24 leading-30 font-semibold tracking-tight;
    }

    .title-h4 {
      @apply text-20 leading-26 font-semibold tracking-tight;
    }

    .body-regular {
      @apply text-18 leading-24 font-normal tracking-tight;
    }

    .body-semibold {
      @apply text-18 leading-24 font-semibold tracking-tight;
    }

    .body-xs {
      @apply text-16 leading-20 font-normal tracking-tight;
    }

    .body-xs-semibold {
      @apply text-16 leading-20 font-semibold tracking-tight;
    }

    .body-m-regular {
      @apply text-16 leading-24 font-normal tracking-tight;
    }

    .body-m-semibold {
      @apply text-16 leading-24 font-semibold tracking-tight;
    }

    .body-s {
      @apply text-14 leading-18 font-normal tracking-tight;
    }

    .body-s-semibold {
      @apply text-14 leading-18 font-semibold tracking-tight;
    }

    .numbers-l {
      @apply text-56 leading-56 font-semibold;
    }

    .numbers-m {
      @apply text-48 leading-48 font-semibold;
    }

    .numbers-s {
      @apply text-40 leading-48 font-semibold;
    }

    .button-l {
      @apply text-18 leading-24 font-semibold tracking-tight;
    }

    .button-m {
      @apply text-16 leading-24 font-semibold tracking-tight;
    }

    .ticker {
      @apply text-20 leading-24 font-semibold;
    }

    .uppercase-label {
      @apply text-11 leading-12 font-semibold tracking-widest uppercase;
    }
  }
}
